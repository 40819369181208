import React, { Component } from 'react';
// import './App.css';
import'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/main.scss';
// import './assets/css/styles.css';
import './assets/css/custom.css';

import Header from './component/Sections/Header';
import Android from './Android';
import IOS from './iOS';
import {isIOS, isAndroid} from 'react-device-detect';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<div>
				<Header />
			</div>
		);
	}
}

export default App;
