import React, { Component } from 'react';
import AWS from 'aws-sdk';
import stagingSES from '../../data/constants';
import SectionTitle from '../Elements/SectionTitle';
import { FaHome, FaHeadphones, FaEnvelope } from 'react-icons/fa';
import contactData from '../../data/contact';
import ReCAPTCHA from 'react-google-recaptcha';
import Notification from '../../NotificationService';

class Contact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contactData,
			name: '',
			email: '',
			subject: '',
			message: '',
			mailSent: false,
			error: null
		};
		const reCaptchaRef={}
	}

	handleSubmit(e) {
		e.preventDefault()
		AWS.config.update({
			region: 'us-east-1',
			accessKeyId: stagingSES.AccessKey,
			secretAccessKey: stagingSES.SecretKey,
		});
		const { name, email, subject, message } = this.state
		var params = {
			Destination: {
				ToAddresses: [stagingSES.ToAddress]
			},
			Message: {
				Body: {
					Html: {
						Charset: "UTF-8",
						Data: "<html><body><div>" +
								`<p><b>Name : &nbsp;</b><span>${name}</span></p>` +
								`<p><b>Email : &nbsp;</b><span>${email}</span></p>` +
								`<p><b>Message : &nbsp;</b><span>${message}</span></p>` +
								"</div></body></html>"
					},
				},
				Subject: {
					Charset: 'UTF-8',
					Data: subject
				}
			},
			Source: stagingSES.FromAddress,
		};
		console.log(params);
		var sendPromise = new AWS.SES({ apiVersion: '2010-12-01' }).sendEmail(params).promise();
		sendPromise.then(
			function (data) {
				Notification('info','We have received your request, our support team will contact you soon.');
			}
		).catch(
			function (err) {
				Notification('error','Message sent failed');
			}
		).finally(()=>{
			this.resetForm();
			this.reCaptchaRef.reset();
			document.getElementById('sendMessageBtn').disabled=true;
			document.getElementById('sendMessageBtn').style.color='#adad85';
		});
	}

	resetForm() {
		this.setState({
			name: '',
			email: '',
			subject: '',
			message: '',
		})
	}
	handleChange = (param, e) => {
		this.setState({ [param]: e.target.value })
	}
	onChange(value){
		console.log(value);
		var btn=document.getElementById('sendMessageBtn');
		if(value!=null)
		{
			btn.disabled=false;
			btn.style.color='white';
		}
		else{
			btn.disabled=true;
			btn.style.color='#adad85';
		}
	}
	render() {
		return (
			<section className="contact-area" id="contact">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-8 text-center">
							<SectionTitle title={this.state.contactData.title} text={this.state.contactData.text} alignLine="center" />
						</div>
					</div>
					<div className="row align-items-center">
						<div className="row col-sm-12 col-md-10 mx-auto">
							<div className="contact-info col-12 col-sm-12 col-md-5">
								<div className="single-info d-flex">
									<div className="info-icon">
										<FaHome />
									</div>
									<div className="info-desc">
										<h5>Jefferson City, MO 65109</h5>
										<p>1022 Northeast Dr, Suite A</p>
									</div>
								</div>
								<div className="single-info d-flex">
									<div className="info-icon">
										<FaHeadphones />
									</div>
									<div className="info-desc">
										<h5>+1 866-200-0106 </h5>
										<p>Extension 105</p>
									</div>
								</div>
								<div className="single-info d-flex">
									<div className="info-icon">
										<FaEnvelope />
									</div>
									<div className="info-desc">
										<h5>contact@csympl.com</h5>
										<p>Send us your query anytime!</p>
									</div>
								</div>
							</div>
							<div className="contact-form-section col-12 col-sm-12 col-md-7">
								<form
									className="contact-form-area contact-page-form contact-form text-right"
									id="myForm"
									action="#"
									method="post"
									onSubmit={this.handleSubmit.bind(this)}
								>
									<div className="row">
										<div className="col-lg-12 form-group">
											<input
												id="name"
												name="name"
												placeholder="Enter your name"
												className="mb-20 form-control"
												required="required"
												type="text" 
												value={this.state.name}
												onChange={this.handleChange.bind(this, 'name')}
											/>
											<input
												id="email"
												name="email"
												placeholder="Enter email address"
												className="mb-20 form-control"
												required="required"
												type="email"
												value={this.state.email}
												onChange={this.handleChange.bind(this, 'email')}
											/>
											<input
												id="subject"
												name="subject"
												placeholder="Enter your subject"
												className="mb-20 form-control"
												required="required"
												type="text"
												value={this.state.subject}
												onChange={this.handleChange.bind(this, 'subject')}
											/>

											<textarea
												id="message"
												className="mt-10 form-control"
												name="message"
												placeholder="Message"
												required="required"
												value={this.state.message}
												onChange={this.handleChange.bind(this, 'message')}
											/>
										</div>
										&emsp;
										<ReCAPTCHA ref={(r)=>{this.reCaptchaRef=r;}} sitekey="6LfN_aIZAAAAALOHv0uDCOfW-JluUeYWfhPy3GZl" onChange={this.onChange}/>
										<div className="col-lg-12 text-left"  style={{lineHeight:'5'}}>
											<button id="sendMessageBtn" 
													className="secondary-btn text-uppercase"
													disabled 
													type="submit"
											>Send Message</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Contact;
