import React from 'react'
import {isIOS, isAndroid} from 'react-device-detect';

import AndroidLogo from './assets/img/playStore.png'
import AppStoreLogo from './assets/img/appStore.png'
import SPlachScreen from './assets/img/SPlachScreens.jpg'


function IOS() {
    return (
        <div style={{height: '100vh', backgroundImage: `url(${SPlachScreen})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}} className="d-flex align-items-center justify-content-center">

            <a href="https://apps.apple.com/kz/app/csympl/id1521144086" className="text-center"> <img src={AppStoreLogo} className="w-50"/> </a> 
            
        </div>
    )
}

export default IOS
